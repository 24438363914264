import {
  Box,
  CircularProgress,
  FieldArray,
  FormControlLabel,
  FormGroup,
  FormikProvider,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  theme,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInitialCompesatoryHistoricValues } from "../../common/FormUtils";
import { handleTextFieldChanged } from "../../common/Utils";
import { StoreState } from "../../models/states/StoreState";
import { getTensionLevel } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { onTechnicalInformationAutoCompleteChanged } from "../../store/slices/createServiceSlice";
import { AppDispatch } from "../../store/store";
import { StepFormType } from "../estate-information/EstateInformation";
import CardTitleDynamicTable from "./components/CardTitleDynamicTable";

const TechnicalInformationForm = ({ formik, index, disabled }: StepFormType) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    isLoadingTensionLevel,
    paginationTensionLevel,
    isLoadingAutocompleteInfo,
    autocompleteInfo,
    technicalInformationAutoCompleted,
  } = useSelector((state: StoreState) => state.createServiceForm);

  const [selectedTensionLevel, setSelectedTensionLevel] = useState<string | undefined>();

  useEffect(() => {
    if (!isLoadingAutocompleteInfo && autocompleteInfo && !technicalInformationAutoCompleted) {
      dispatch(onTechnicalInformationAutoCompleteChanged(true));
      const { technical_information } = autocompleteInfo;

      if (technical_information?.power_capacity_contracted) {
        handleTextFieldChanged(technical_information.power_capacity_contracted, "installedCapacity", formik);
      }
      if (technical_information?.niu) {
        handleTextFieldChanged(technical_information.niu, "niu", formik);
      }

      if (technical_information?.initial_average_active_energy_usage) {
        handleTextFieldChanged(technical_information.initial_average_active_energy_usage, "averageUsage", formik);
      }
      if (technical_information?.circuit_code) {
        handleTextFieldChanged(technical_information.circuit_code, "circuitCode", formik);
      }
      if (technical_information?.electric_transformer_code) {
        handleTextFieldChanged(technical_information.electric_transformer_code, "transformerCode", formik);
      }

      if (technical_information?.voltage_level_sui_code) {
        setSelectedTensionLevel(technical_information.voltage_level_sui_code);
      }
    }
  }, [autocompleteInfo]);

  // Tension level
  useEffect(() => {
    if (!paginationTensionLevel || paginationTensionLevel.length === 0) {
      dispatch(getTensionLevel());
    }
  }, []);

  useEffect(() => {
    if (paginationTensionLevel && paginationTensionLevel.length > 0 && selectedTensionLevel) {
      const tensionLevel = paginationTensionLevel.find((tensionLevel) => tensionLevel.sui_code == selectedTensionLevel);
      if (tensionLevel) {
        handleTextFieldChanged(tensionLevel.id, "tensionLevelId", formik);
      }
      setSelectedTensionLevel(undefined);
    }
  }, [selectedTensionLevel, paginationTensionLevel]);

  return (
    <>
      <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.primary.main, marginTop: "44px" }}>
        {index}. Información técnica
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Capacidad instalada (kW)
            </InputLabel>
            <TextField
              disabled={disabled}
              fullWidth
              className="TextField-without-border-radius"
              id="installedCapacity"
              name="installedCapacity"
              variant="outlined"
              value={formik.values.installedCapacity}
              helperText={formik.touched.installedCapacity && formik.errors.installedCapacity}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "installedCapacity", formik);
              }}
              error={formik.touched.installedCapacity && Boolean(formik.errors.installedCapacity)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              NIU
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="niu"
              name="niu"
              variant="outlined"
              value={formik.values.niu}
              helperText={formik.touched.niu && formik.errors.niu}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "niu", formik);
              }}
              error={formik.touched.niu && Boolean(formik.errors.niu)}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Código del circuito
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="circuitCode"
              name="circuitCode"
              value={formik.values.circuitCode}
              helperText={formik.touched.circuitCode && formik.errors.circuitCode}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "circuitCode", formik);
              }}
              error={formik.touched.circuitCode && Boolean(formik.errors.circuitCode)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Código del transformador
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="transformerCode"
              name="transformerCode"
              value={formik.values.transformerCode}
              helperText={formik.touched.transformerCode && formik.errors.transformerCode}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "transformerCode", formik);
              }}
              error={formik.touched.transformerCode && Boolean(formik.errors.transformerCode)}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel shrink className="Input-label">
          Consumo promedio (Kwh)
        </InputLabel>
        <TextField
          fullWidth
          className="TextField-without-border-radius"
          id="averageUsage"
          name="averageUsage"
          variant="outlined"
          value={formik.values.averageUsage}
          helperText={formik.touched.averageUsage && formik.errors.averageUsage}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "averageUsage", formik);
          }}
          error={formik.touched.averageUsage && Boolean(formik.errors.averageUsage)}
        />
      </FormGroup>
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel shrink className="Input-label">
          Código de cuenta en el operador de red
        </InputLabel>
        <TextField
          fullWidth
          className="TextField-without-border-radius"
          id="codeNetworkOperator"
          name="codeNetworkOperator"
          variant="outlined"
          value={formik.values.codeNetworkOperator}
          helperText={formik.touched.codeNetworkOperator && formik.errors.codeNetworkOperator}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "codeNetworkOperator", formik);
          }}
          error={formik.touched.codeNetworkOperator && Boolean(formik.errors.codeNetworkOperator)}
        />
      </FormGroup>
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel id="radioButtonsTensionLevel">Nivel de tensión</InputLabel>
        <RadioGroup
          aria-labelledby="radioButtonsTensionLevel"
          value={formik.values.tensionLevelId}
          name="tensionLevelId"
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "tensionLevelId", formik);
          }}
        >
          {isLoadingTensionLevel ? (
            <CircularProgress />
          ) : (
            paginationTensionLevel.map((tensionLevel) => {
              return (
                <FormControlLabel
                  key={tensionLevel.id}
                  value={tensionLevel.id}
                  sx={{ marginTop: "16px" }}
                  control={<Radio color="secondary" />}
                  label={tensionLevel.description}
                />
              );
            })
          )}
        </RadioGroup>
      </FormGroup>
      <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.primary.main, marginTop: "44px" }}>
        4.1 Energía reactiva
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Contador de reactiva m
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="reactiveCounterM"
              name="reactiveCounterM"
              variant="outlined"
              value={formik.values.reactiveCounterM}
              helperText={
                (formik.touched.reactiveCounterM && formik.errors.reactiveCounterM) ??
                "Si no se tiene este valor poner 1"
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "reactiveCounterM", formik);
              }}
              error={formik.touched.reactiveCounterM && Boolean(formik.errors.reactiveCounterM)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Contador de reactiva m=1
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="reactiveCounterM1"
              name="reactiveCounterM1"
              variant="outlined"
              value={formik.values.reactiveCounterM1}
              helperText={
                (formik.touched.reactiveCounterM1 && formik.errors.reactiveCounterM1) ??
                "Si no se tiene este valor poner 0"
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "reactiveCounterM1", formik);
              }}
              error={formik.touched.reactiveCounterM1 && Boolean(formik.errors.reactiveCounterM1)}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Contador de reactiva m=6
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="reactiveCounterM6"
              name="reactiveCounterM6"
              variant="outlined"
              value={formik.values.reactiveCounterM6}
              helperText={
                (formik.touched.reactiveCounterM6 && formik.errors.reactiveCounterM6) ??
                "Si no se tiene este valor poner 0"
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "reactiveCounterM6", formik);
              }}
              error={formik.touched.reactiveCounterM6 && Boolean(formik.errors.reactiveCounterM6)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Cantidad de periodos sin penalizar
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="numberOfPeriodsWithoutPenalty"
              name="numberOfPeriodsWithoutPenalty"
              variant="outlined"
              value={formik.values.numberOfPeriodsWithoutPenalty}
              helperText={
                (formik.touched.numberOfPeriodsWithoutPenalty && formik.errors.numberOfPeriodsWithoutPenalty) ??
                "Si no se tiene este valor poner 0"
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "numberOfPeriodsWithoutPenalty", formik);
              }}
              error={
                formik.touched.numberOfPeriodsWithoutPenalty && Boolean(formik.errors.numberOfPeriodsWithoutPenalty)
              }
            />
          </FormGroup>
        </Grid>
      </Grid>{" "}
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{
          marginTop: "44px",
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          ¿Tienes historial de compensación?
        </Typography>
        <Switch
          id="hasCompensationHistory"
          name="hasCompensationHistory"
          checked={formik.values.hasCompensationHistory}
          onChange={(_, checked) => {
            handleTextFieldChanged(checked, "hasCompensationHistory", formik);
            if (!checked) {
              handleTextFieldChanged(getInitialCompesatoryHistoricValues(), "compensationHistory", formik);
            }
          }}
          color="secondary"
        />
      </Grid>
      {formik.values.hasCompensationHistory && (
        <Box>
          <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.primary.main, marginTop: "44px" }}>
            4.2 Historial de compensación
          </Typography>
          <Box
            mt={5}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} mr={2}>
              <Grid item xs={4}>
                <CardTitleDynamicTable title="Meses" />
              </Grid>
              <Grid item xs={4}>
                <CardTitleDynamicTable title="Horas" />
              </Grid>
              <Grid item xs={4}>
                <CardTitleDynamicTable title="Veces" />
              </Grid>
            </Grid>
          </Box>
          <FormikProvider value={formik}>
            <FieldArray
              name="compensationHistory"
              validateOnChange={true}
              render={(arrayHelpers) => (
                <div>
                  {formik.values.compensationHistory.map((compensationHistory, index) => {
                    return (
                      <Box
                        key={index}
                        mt={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid container spacing={2} mr={2}>
                          <Grid item xs={4}>
                            <TextField
                              disabled
                              fullWidth
                              className="TextField-without-border-radius"
                              id={`compensationHistory[${index}].month`}
                              name={`compensationHistory[${index}].month`}
                              variant="outlined"
                              value={formik.values.compensationHistory[index].month}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              className="TextField-without-border-radius"
                              id={`compensationHistory[${index}].hour`}
                              name={`compensationHistory[${index}].hour`}
                              variant="outlined"
                              value={formik.values.compensationHistory[index].hour}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                e.preventDefault();
                                handleTextFieldChanged(e.target.value, `compensationHistory.${index}.hour`, formik);
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              className="TextField-without-border-radius"
                              id={`compensationHistory[${index}].time`}
                              name={`compensationHistory[${index}].time`}
                              variant="outlined"
                              value={formik.values.compensationHistory[index].time}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                e.preventDefault();
                                handleTextFieldChanged(e.target.value, `compensationHistory.${index}.time`, formik);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </div>
              )}
            />
          </FormikProvider>
        </Box>
      )}
    </>
  );
};

export default TechnicalInformationForm;
