import { Autocomplete, FormGroup, Grid, InfoIcon, InputLabel, TextField, Typography, theme } from "@enerbit/base";
import { useSelector } from "react-redux";
import { handleTextFieldChanged } from "../../common/Utils";
import { StoreState } from "../../models/states/StoreState";
import { StepFormType } from "../estate-information/EstateInformation";

export const EstateSelection = ({ formik, index }: StepFormType) => {
  const { paginationLeads } = useSelector((state: StoreState) => state.createServiceForm);

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <InfoIcon color="secondary" sx={{ marginRight: "12px" }} />
        <Typography variant="h6" sx={{ color: theme.palette.primary.main, width: "95%" }}>
          La información autocompletada en los campos siguientes, fue importada de la que se diligenció anteriormente en
          la documentación.
        </Typography>
      </Grid>
      <Typography variant="h6" fontWeight="bold" sx={{ color: theme.palette.primary.main, marginTop: "44px" }}>
        {index}. Información de la oportunidad
      </Typography>
      {(paginationLeads?.items?.length ?? -1) > 0 && formik.values.estate && (
        <FormGroup sx={{ marginTop: "36px" }}>
          <InputLabel shrink className="Input-label">
            Seleccione la oportunidad
          </InputLabel>
          <Autocomplete
            className="TextField-without-border-radius"
            id="estate"
            getOptionLabel={(option) => option.address}
            value={paginationLeads?.items.find((lead) => lead.id === formik.values.estate)}
            disableClearable
            options={paginationLeads?.items ?? []}
            onChange={(_e, value) => {
              if (value || null) {
                handleTextFieldChanged(value.id, "estate", formik);
              }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField name="estate" {...params} />}
          />
        </FormGroup>
      )}
    </>
  );
};
