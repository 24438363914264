import { CircularProgress, FormGroup, Grid, InputLabel, MenuItem, TextField, Typography } from "@enerbit/base";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { FormikType, StringUtils, handleTextFieldChanged } from "../../../common/Utils";
import { CreateServiceFormType } from "../../../models/form/CreateServiceTypeForm";
import { StoreState } from "../../../models/states/StoreState";

export type LeadContent = {
  formik: FormikType<CreateServiceFormType>;
  disabled?: boolean;
};

export const LeadContent = ({ formik, disabled }: LeadContent) => {
  const _handleOnChangeCountrySelected = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    formik.setValues({
      ...formik.values,
      selectedCityId: "",
      selectedStateId: "",
    });
    handleTextFieldChanged(event.target.value, "selectedCountryId", formik);
  };

  const _handleOnSelectedStateIdChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    formik.setValues({
      ...formik.values,
      selectedCityId: "",
    });
    handleTextFieldChanged(event.target.value, "selectedStateId", formik);
  };

  const _handleOnSelectedCityIdChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    formik.handleChange(event);
  };

  const {
    paginationDataCountries,
    paginationDataStates,
    paginationDataCities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
    paginationSocialStratum,
    isLoadingSocialStratums,
    catastralTypes,
    isLoadingCatastralTypes,
  } = useSelector((state: StoreState) => state.createServiceForm);

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              País
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="selectedCountryId"
              name="selectedCountryId"
              variant="outlined"
              value={formik.values.selectedCountryId}
              helperText={formik.touched.selectedCountryId && formik.errors.selectedCountryId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                _handleOnChangeCountrySelected(e);
              }}
              error={formik.touched.selectedCountryId && Boolean(formik.errors.selectedCountryId)}
            >
              {isLoadingCountries ? (
                <CircularProgress />
              ) : (
                paginationDataCountries?.items.map((country) => {
                  return (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  );
                }) ?? <Typography variant="body1">No hemos encontrado ningún resultado</Typography>
              )}
            </TextField>
          </FormGroup>
        </Grid>

        {formik.values.selectedCountryId != "" && formik.values.selectedCountryId && (
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Departamento
              </InputLabel>
              <TextField
                fullWidth
                select
                className="TextField-without-border-radius"
                id="selectedStateId"
                name="selectedStateId"
                variant="outlined"
                value={formik.values.selectedStateId}
                onChange={_handleOnSelectedStateIdChanged}
                error={formik.touched.selectedStateId && Boolean(formik.errors.selectedStateId)}
                helperText={formik.touched.selectedStateId && formik.errors.selectedStateId}
              >
                {isLoadingStates ? (
                  <CircularProgress />
                ) : (
                  paginationDataStates?.items.map((state) => {
                    return (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    );
                  }) ?? <Typography variant="body1">No hemos encontrado ningún resultado</Typography>
                )}
              </TextField>
            </FormGroup>
          </Grid>
        )}

        {formik.values.selectedStateId != "" && formik.values.selectedStateId && (
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Ciudad
              </InputLabel>
              <TextField
                fullWidth
                select
                className="TextField-without-border-radius"
                id="selectedCityId"
                name="selectedCityId"
                variant="outlined"
                value={formik.values.selectedCityId}
                onChange={_handleOnSelectedCityIdChanged}
                error={formik.touched.selectedCityId && Boolean(formik.errors.selectedCityId)}
                helperText={formik.touched.selectedCityId && formik.errors.selectedCityId}
              >
                {isLoadingCities ? (
                  <CircularProgress />
                ) : (
                  paginationDataCities?.items.map((city) => {
                    return (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    );
                  }) ?? <Typography variant="body1">No hemos encontrado ningún resultado</Typography>
                )}
              </TextField>
            </FormGroup>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Dirección
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="address"
              name="address"
              variant="outlined"
              value={formik.values.address}
              helperText={formik.touched.address && formik.errors.address}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "address", formik);
              }}
              error={formik.touched.address && Boolean(formik.errors.address)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Estrato
            </InputLabel>
            <TextField
              disabled={disabled}
              fullWidth
              select
              className="TextField-without-border-radius"
              id="socialStratumId"
              name="socialStratumId"
              variant="outlined"
              value={formik.values.socialStratumId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "socialStratumId", formik);
              }}
              error={formik.touched.socialStratumId && Boolean(formik.errors.socialStratumId)}
              helperText={formik.touched.socialStratumId && formik.errors.socialStratumId}
            >
              {isLoadingSocialStratums ? (
                <CircularProgress />
              ) : (
                paginationSocialStratum?.items.map((socialStratum) => {
                  return (
                    <MenuItem key={socialStratum.id} value={socialStratum.id}>
                      {`${StringUtils.capitalizeFirstLetter(socialStratum.description)} - ${socialStratum.code}`}
                    </MenuItem>
                  );
                }) ?? <Typography variant="body1">No hemos encontrado ningún resultado</Typography>
              )}
            </TextField>
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Tipo de identificador catastral
            </InputLabel>
            <TextField
              disabled={disabled}
              fullWidth
              select
              className="TextField-without-border-radius"
              id="catastralTypeId"
              name="catastralTypeId"
              variant="outlined"
              value={formik.values.catastralTypeId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "catastralTypeId", formik);
              }}
              error={formik.touched.catastralTypeId && Boolean(formik.errors.catastralTypeId)}
              helperText={formik.touched.catastralTypeId && formik.errors.catastralTypeId}
            >
              {isLoadingCatastralTypes ? (
                <CircularProgress />
              ) : (
                (catastralTypes ?? []).map((catastralType) => {
                  return (
                    <MenuItem key={catastralType.id} value={catastralType.id}>
                      {StringUtils.capitalizeFirstLetter(catastralType.description)}
                    </MenuItem>
                  );
                }) ?? <Typography variant="body1">No hemos encontrado ningún resultado</Typography>
              )}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Identificador catastral
            </InputLabel>
            <TextField
              disabled={disabled}
              fullWidth
              className="TextField-without-border-radius"
              id="catastralIdentifier"
              name="catastralIdentifier"
              variant="outlined"
              value={formik.values.catastralIdentifier}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "catastralIdentifier", formik);
              }}
              error={formik.touched.catastralIdentifier && Boolean(formik.errors.catastralIdentifier)}
              helperText={formik.touched.catastralIdentifier && formik.errors.catastralIdentifier}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Longitud
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="longitude"
              name="longitude"
              variant="outlined"
              value={formik.values.longitude}
              helperText={formik.touched.longitude && formik.errors.longitude}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "longitude", formik);
              }}
              error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Latitud
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="latitude"
              name="latitude"
              variant="outlined"
              value={formik.values.latitude}
              helperText={formik.touched.latitude && formik.errors.latitude}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "latitude", formik);
              }}
              error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            />
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Altitud
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="altitude"
              name="altitude"
              variant="outlined"
              value={formik.values.altitude}
              helperText={formik.touched.altitude && formik.errors.altitude}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "altitude", formik);
              }}
              error={formik.touched.altitude && Boolean(formik.errors.altitude)}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel shrink className="Input-label">
          Plus code
        </InputLabel>
        <TextField
          fullWidth
          className="TextField-without-border-radius"
          id="plusCode"
          name="plusCode"
          variant="outlined"
          value={formik.values.plusCode}
          helperText={formik.touched.plusCode && formik.errors.plusCode}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "plusCode", formik);
          }}
          error={formik.touched.plusCode && Boolean(formik.errors.plusCode)}
        />
      </FormGroup>
    </>
  );
};
