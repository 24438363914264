import {
  AdapterDayjs,
  AdapterMoment,
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  DatePicker,
  DesktopDatePicker,
  FormControlLabel,
  FormGroup,
  FormikType,
  Grid,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  enerbitColors,
  theme,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  shouldShowDependentAutoGeneratorTypeFields,
  shouldShowDependentExportEnergyFields,
} from "../../common/FormUtils";
import { handleTextFieldChanged } from "../../common/Utils";
import { CreateServiceFormType } from "../../models/form/CreateServiceTypeForm";
import { Frontier, importExportStatus } from "../../models/frontier/frontier";
import { Service } from "../../models/props/PropsApp";
import { StoreState } from "../../models/states/StoreState";
import {
  getElectricityMarketInfo,
  getMarkets,
  getRetailers,
} from "../../store/actions/electricity-market-info/electricity-market-info.actions";
import {
  getAssetOwnership,
  getAutoGeneratorTypes,
  getExportEnergy,
  getExportEnergyGenerationTypes,
  getPortafolioRiskUserTypes,
  getQualityGroups,
  getSpecialAreaTypes,
  getSpecialConditions,
  getSubsistenceConsumptions,
  getSuiSocialStratums,
  getZonesSui,
} from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { onRegulatoryInformationAutoCompleteChanged } from "../../store/slices/createServiceSlice";
import { AppDispatch } from "../../store/store";

export type StepFormTypeRegulatory = {
  formik: FormikType<CreateServiceFormType>;
  index: number;
  service?: Service | null;
  disabled?: boolean;
  frontiers?: Frontier[] | null;
};

const RegulatoryInformationForm = ({
  formik,
  index,
  service,
  disabled,
  frontiers,
}: StepFormTypeRegulatory) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    paginationElectricityMarketInfo,
    isLoadingAssetOwnership,
    paginationAssetOwnership,
    paginationRetailer,
    isLoadingSubsistenceConsumption,
    subsistenceConsumption,
    isLoadingPortafolioRiskUserType,
    portafolioRiskUserTypes,
    isLoadingQualityGroups,
    qualityGroups,
    isLoadingZoneSui,
    zonesSui,
    isLoadingSpecialConditions,
    specialConditions,
    isLoadingSpecialAreaTypes,
    specialAreaTypes,
    isLoadingSuiSocialStratums,
    suiSocialStratum,
    autocompleteInfo,
    isLoadingAutocompleteInfo,
    paginationMarkets,
    isLoadingAutoGeneratorTypes,
    autogeneratorTypes,
    isLoadingExportEnergyGenerationTypes,
    exportEnergyGenerationTypes,
    isLoadingExportEnergy,
    exportEnergy,
    regulatoryInformationAutoCompleted,
  } = useSelector((state: StoreState) => state.createServiceForm);

  const [selectedRetailer, setSelectedRetailer] = useState<
    string | undefined
  >();
  const [selectedAssetOwnership, setSelectedAssetOwnership] = useState<
    string | undefined
  >();
  const [selectedQualityGroup, setSelectedQualityGroup] = useState<
    string | undefined
  >();
  const [selectedSuiSocialStratum, setSelectedSuiSocialStratum] = useState<
    string | undefined
  >();
  const [selectedZoneSui, setSelectedZoneSui] = useState<string | undefined>();
  const [selectedSpecialAreaType, setSelectedSpecialAreaType] = useState<
    string | undefined
  >();
  const [selectedSpecialCode, setSelectedSpecialCode] = useState<
    string | undefined
  >();
  const [selectedPortfolioRiskType, setSelectedPortfolioRiskType] = useState<
    string | undefined
  >();

  interface FrontierBoxProps {
    frontiers: Frontier[];
    frontierType: string;
  }

  const ValidateFrontierBox = ({
    frontiers,
    frontierType,
  }: FrontierBoxProps) => {
    const frontierTypeFound = frontiers.find(
      (frontier) => frontier.frontier_type === frontierType
    );
    return (
      <Box sx={{ marginTop: "20px" }}>
        <Typography
          sx={{
            color: enerbitColors.warning.main,
            mt: "10px",
            fontWeight: "700",
          }}
        >
          {frontierType == importExportStatus.ImportFrontier
            ? "Importadora"
            : "Exportadora"}
        </Typography>
        {frontierTypeFound ? (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <InputLabel shrink className="Input-label">
                  Código de frontera
                </InputLabel>
                <TextField
                  disabled={disabled}
                  fullWidth
                  className="TextField-without-border-radius"
                  id="frontierId"
                  value={frontierTypeFound.frontier_xm_code}
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <InputLabel shrink className="Input-label">
                  Registro de frontera
                </InputLabel>
                <TextField
                  disabled={disabled}
                  fullWidth
                  className="TextField-without-border-radius"
                  id="frontierId"
                  value={moment
                    .parseZone(frontierTypeFound.frontier_xm_registered_from)
                    .format("DD/MM/YYYY")}
                />
              </FormGroup>
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              ¿Es frontera?
            </Typography>
            <TextField
              disabled={disabled}
              fullWidth
              className="TextField-without-border-radius"
              id="isFrontier"
              value={"No"}
            />
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (
      !isLoadingAutocompleteInfo &&
      autocompleteInfo &&
      !regulatoryInformationAutoCompleted
    ) {
      dispatch(onRegulatoryInformationAutoCompleteChanged(true));
      const { regulatory_information } = autocompleteInfo;
      if (regulatory_information?.market_sui_code) {
        setSelectedRetailer(regulatory_information.market_sui_code);
      }
      if (regulatory_information?.xm_frontier_code) {
        handleTextFieldChanged(
          regulatory_information.xm_frontier_code,
          "frontierId",
          formik
        );
      }
      if (
        regulatory_information?.xm_register_from &&
        !formik.values.frontierRegisterDate
      ) {
        handleTextFieldChanged(
          moment(regulatory_information.xm_frontier_code),
          "frontierRegisterDate",
          formik
        );
      }
      // formik.values.assetOwnershipId;
      if (regulatory_information?.asset_ownership_sui_code) {
        setSelectedAssetOwnership(
          regulatory_information.asset_ownership_sui_code
        );
      }
      if (regulatory_information?.quality_group_sui_code) {
        setSelectedQualityGroup(regulatory_information.quality_group_sui_code);
      }
      if (regulatory_information?.sui_social_stratum_code) {
        setSelectedSuiSocialStratum(
          regulatory_information.sui_social_stratum_code
        );
      }
      if (regulatory_information?.zones_sui_code) {
        setSelectedZoneSui(regulatory_information.zones_sui_code);
      }
      if (regulatory_information?.special_condition_code) {
        setSelectedSpecialCode(regulatory_information.special_condition_code);
      }
      if (regulatory_information?.special_area_type_code) {
        setSelectedSpecialAreaType(
          regulatory_information.special_area_type_code
        );
      }
      if (regulatory_information?.portafolio_risk_user_type_sui_code) {
        setSelectedPortfolioRiskType(
          regulatory_information.portafolio_risk_user_type_sui_code
        );
      }
    }
  }, [autocompleteInfo]);

  // ElectricityMarketInfo
  useEffect(() => {
    if (
      !paginationElectricityMarketInfo ||
      paginationElectricityMarketInfo.items.length === 0
    ) {
      dispatch(getElectricityMarketInfo());
    }
  }, []);

  // AssetOwnership
  useEffect(() => {
    if (!paginationAssetOwnership || paginationAssetOwnership.length === 0) {
      dispatch(getAssetOwnership());
    }
  }, []);

  useEffect(() => {
    if (paginationAssetOwnership && paginationAssetOwnership.length > 0) {
      const item = paginationAssetOwnership.find(
        (item) => item.sui_code == selectedAssetOwnership
      );
      if (item) {
        handleTextFieldChanged(item.id, "assetOwnershipId", formik);
      }
      setSelectedAssetOwnership(undefined);
    }
  }, [selectedAssetOwnership, paginationAssetOwnership]);

  // SubsistenceConsumption
  useEffect(() => {
    if (!subsistenceConsumption || subsistenceConsumption.length === 0) {
      dispatch(getSubsistenceConsumptions());
    }
  }, []);

  // PorfolioRiskUserTypes
  useEffect(() => {
    if (!portafolioRiskUserTypes || portafolioRiskUserTypes.length === 0) {
      dispatch(getPortafolioRiskUserTypes());
    }
  }, []);

  useEffect(() => {
    if (portafolioRiskUserTypes && portafolioRiskUserTypes.length > 0) {
      const item = portafolioRiskUserTypes.find(
        (item) => item.sui_code == selectedPortfolioRiskType
      );
      if (item) {
        handleTextFieldChanged(item.id, "portafolioRiskUserTypeId", formik);
      }
      setSelectedPortfolioRiskType(undefined);
    }
  }, [selectedPortfolioRiskType, portafolioRiskUserTypes]);

  // Retailer
  useEffect(() => {
    if (!paginationRetailer || paginationRetailer.items.length === 0) {
      dispatch(getRetailers());
    }
  }, []);

  // Market
  useEffect(() => {
    if (!paginationMarkets || paginationMarkets.items.length === 0) {
      dispatch(getMarkets());
    }
  }, []);

  useEffect(() => {
    if (paginationRetailer && paginationRetailer.items.length > 0) {
      const retailer = paginationRetailer.items.find(
        (retailer) => retailer.sui_code == selectedRetailer
      );
      if (retailer) {
        handleTextFieldChanged(retailer.id, "marketerId", formik);
      } else {
        const enerbitRetailer = paginationRetailer.items.find((value) =>
          value.description.toLowerCase().includes("enerbit")
        );
        if (enerbitRetailer) {
          handleTextFieldChanged(enerbitRetailer.id, "marketerId", formik);
        } else {
          handleTextFieldChanged(
            paginationRetailer.items[0].id,
            "marketerId",
            formik
          );
        }
      }
      setSelectedRetailer(undefined);
    }
  }, [selectedRetailer, paginationRetailer]);

  // QualityGroups
  useEffect(() => {
    if (!qualityGroups || qualityGroups.length === 0) {
      dispatch(getQualityGroups());
    }
  }, []);

  useEffect(() => {
    if (qualityGroups && qualityGroups.length > 0) {
      const item = qualityGroups.find(
        (item) => item.sui_code == selectedQualityGroup
      );
      if (item) {
        handleTextFieldChanged(item.id, "qualityGroupId", formik);
      }
      setSelectedQualityGroup(undefined);
    }
  }, [selectedQualityGroup, qualityGroups]);

  // ZonesSUI
  useEffect(() => {
    if (!zonesSui || zonesSui.length === 0) {
      dispatch(getZonesSui());
    }
  }, []);

  useEffect(() => {
    if (zonesSui && zonesSui.length > 0) {
      const item = zonesSui.find((item) => item.sui_code == selectedZoneSui);
      if (item) {
        handleTextFieldChanged(item.id, "zoneSuiId", formik);
      }
      setSelectedZoneSui(undefined);
    }
  }, [selectedZoneSui, zonesSui]);

  // Special Conditions
  useEffect(() => {
    if (!specialConditions || specialConditions.length === 0) {
      dispatch(getSpecialConditions());
    }
  }, []);

  useEffect(() => {
    if (specialConditions && specialConditions.length > 0) {
      const item = specialConditions.find(
        (item) => item.sui_code == selectedSpecialCode
      );
      if (item) {
        handleTextFieldChanged(item.id, "specialConditionId", formik);
      }
      setSelectedSpecialCode(undefined);
    }
  }, [selectedSpecialCode, specialConditions]);

  // Special Area Types
  useEffect(() => {
    if (!specialAreaTypes || specialAreaTypes.length === 0) {
      dispatch(getSpecialAreaTypes());
    }
  }, []);

  useEffect(() => {
    if (specialAreaTypes && specialAreaTypes.length > 0) {
      const item = specialAreaTypes.find(
        (item) => item.sui_code == selectedSpecialAreaType
      );
      if (item) {
        handleTextFieldChanged(item.id, "specialAreaTypeId", formik);
      }
      setSelectedSpecialAreaType(undefined);
    }
  }, [selectedSpecialAreaType, specialAreaTypes]);

  // SUI Social Stratum
  useEffect(() => {
    if (!suiSocialStratum || suiSocialStratum.length === 0) {
      dispatch(getSuiSocialStratums());
    }
  }, []);

  useEffect(() => {
    if (suiSocialStratum && suiSocialStratum.length > 0) {
      const item = suiSocialStratum.find(
        (item) => item.sui_code == selectedSuiSocialStratum
      );
      if (item) {
        handleTextFieldChanged(item.id, "suiSocialStratumId", formik);
      }
      setSelectedSuiSocialStratum(undefined);
    }
  }, [selectedSuiSocialStratum, suiSocialStratum]);

  useEffect(() => {
    if (
      !exportEnergyGenerationTypes ||
      exportEnergyGenerationTypes.length == 0
    ) {
      dispatch(getExportEnergyGenerationTypes());
    }
  }, []);

  useEffect(() => {
    if (!autogeneratorTypes || autogeneratorTypes.length == 0) {
      dispatch(getAutoGeneratorTypes());
    }
  }, []);

  useEffect(() => {
    if (!exportEnergy || exportEnergy.length == 0) {
      dispatch(getExportEnergy());
    }
  }, []);

  useEffect(() => {
    if (
      !shouldShowDependentAutoGeneratorTypeFields(
        autogeneratorTypes,
        formik.values.autogeneratorTypeId
      )
    ) {
      handleTextFieldChanged(false, "backUpAgreement", formik);
      handleTextFieldChanged(0, "backUpAgreementPowerCapacity", formik);
      handleTextFieldChanged("", "exportEnergyId", formik);
      handleTextFieldChanged(0, "exportEnergyPowerCapacity", formik);
      handleTextFieldChanged("", "exportEnergyStartDatetime", formik);

      if (!service) {
        //if it doesn't an upgrade
        handleTextFieldChanged("", "frontierXmAutogeneratorCode", formik);
        handleTextFieldChanged("", "exportEnergyGenerationTypeId", formik);
      }
    }
  }, [formik.values.autogeneratorTypeId]);

  useEffect(() => {
    if (!shouldShowDependentExportEnergyFields(exportEnergy, formik)) {
      handleTextFieldChanged(0, "exportEnergyPowerCapacity", formik);
      handleTextFieldChanged("", "exportEnergyStartDatetime", formik);
      handleTextFieldChanged("", "exportEnergyGenerationTypeId", formik);
    }
  }, [formik.values.autogeneratorTypeId]);

  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ color: theme.palette.primary.main, marginTop: "44px" }}
      >
        {index}. Información regulatoria
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          {paginationRetailer && formik.values.marketerId != "" && (
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Comercializador
              </InputLabel>
              <Autocomplete
                id="marketerId"
                fullWidth
                options={paginationRetailer?.items ?? []}
                value={paginationRetailer?.items.find(
                  (value) => value.id == formik.values.marketerId
                )}
                defaultValue={paginationRetailer?.items.find(
                  (value) => value.id == formik.values.marketerId
                )}
                autoHighlight
                getOptionLabel={(option) => option.description}
                onChange={(_e, value) => {
                  if (value) {
                    handleTextFieldChanged(value.id, "marketerId", formik);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="marketerId"
                    className="TextField-without-border-radius"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormGroup>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {paginationMarkets && formik.values.marketingMarketId != "" && (
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Mercado de comercialización
              </InputLabel>
              <Autocomplete
                id="marketingMarketId"
                fullWidth
                options={paginationMarkets?.items ?? []}
                value={paginationMarkets?.items.find(
                  (value) => value.id == formik.values.marketingMarketId
                )}
                autoHighlight
                defaultValue={paginationRetailer?.items.find(
                  (value) => value.id == formik.values.marketingMarketId
                )}
                getOptionLabel={(option) =>
                  `${option.sui_code} - ${option.description}`
                }
                onChange={(_e, value) => {
                  if (value) {
                    handleTextFieldChanged(
                      value.id,
                      "marketingMarketId",
                      formik
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="marketingMarketId"
                    className="TextField-without-border-radius"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormGroup>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ marginTop: "44px" }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          ¿El servicio ya fue registrado en XM?
        </Typography>
        <Switch
          id="hasBeenRegisterOnXm"
          name="hasBeenRegisterOnXm"
          disabled
          checked={formik.values.hasBeenRegisterOnXm}
          onChange={(_, checked) => {
            handleTextFieldChanged(checked, "hasBeenRegisterOnXm", formik);
          }}
          color="secondary"
        />
      </Grid>
      {disabled ? (
        frontiers && frontiers?.length > 0 ? (
          <Box>
            <ValidateFrontierBox
              frontiers={frontiers ?? []}
              frontierType={importExportStatus.ImportFrontier}
            />
            <ValidateFrontierBox
              frontiers={frontiers ?? []}
              frontierType={importExportStatus.ExportFrontier}
            />
          </Box>
        ) : (
          <Box>
            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              ¿Es frontera?
            </Typography>
            <TextField
              disabled={disabled}
              fullWidth
              className="TextField-without-border-radius"
              id="isFrontier"
              value={"No"}
            />
          </Box>
        )
      ) : (
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Código de frontera
              </InputLabel>
              <TextField
                disabled={disabled}
                fullWidth
                className="TextField-without-border-radius"
                id="frontierId"
                name="frontierId"
                variant="outlined"
                value={formik.values.frontierId}
                helperText={
                  formik.touched.frontierId && formik.errors.frontierId
                }
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(e.target.value, "frontierId", formik);
                }}
                error={
                  formik.touched.frontierId && Boolean(formik.errors.frontierId)
                }
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Registro de frontera
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={disabled}
                  onChange={(value: any) => {
                    if (value) {
                      handleTextFieldChanged(
                        moment(value).startOf("day").toISOString(),
                        "frontierRegisterDate",
                        formik
                      );
                    }
                  }}
                  value={formik.values.frontierRegisterDate}
                  slotProps={{
                    textField: (params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "De",
                          format: "yyyy-MM-dd",
                        }}
                        sx={{ width: "100%" }}
                      />
                    ),
                  }}
                />
              </LocalizationProvider>
            </FormGroup>
          </Grid>
        </Grid>
      )}

      <FormGroup sx={{ marginTop: "20px" }}>
        <InputLabel shrink className="Input-label">
          Código dane
        </InputLabel>
        <TextField
          fullWidth
          className="TextField-without-border-radius"
          id="daneCode"
          name="daneCode"
          variant="outlined"
          value={formik.values.daneCode}
          helperText={formik.touched.daneCode && formik.errors.daneCode}
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "daneCode", formik);
          }}
          error={formik.touched.daneCode && Boolean(formik.errors.daneCode)}
        />
      </FormGroup>
      {paginationRetailer && formik.values.oldMarketerId != "" && (
        <FormGroup sx={{ marginTop: "36px" }}>
          <InputLabel shrink className="Input-label">
            Incumbente
          </InputLabel>
          <Autocomplete
            id="oldMarketerId"
            fullWidth
            options={paginationRetailer?.items ?? []}
            value={paginationRetailer?.items.find(
              (value) => value.id == formik.values.oldMarketerId
            )}
            defaultValue={paginationRetailer?.items.find(
              (value) => value.id == formik.values.oldMarketerId
            )}
            autoHighlight
            getOptionLabel={(option) => option.description}
            onChange={(_e, value) => {
              if (value) {
                handleTextFieldChanged(value.id, "oldMarketerId", formik);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="oldMarketerId"
                className="TextField-without-border-radius"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </FormGroup>
      )}
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel id="radioButtonAssetOwnership">
          Propiedad del activo
        </InputLabel>
        <RadioGroup
          aria-labelledby="radioButtonAssetOwnership"
          value={formik.values.assetOwnershipId}
          name="assetOwnershipId"
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(e.target.value, "assetOwnershipId", formik);
          }}
        >
          {isLoadingAssetOwnership ? (
            <CircularProgress />
          ) : (
            paginationAssetOwnership?.map((assetOwnership) => {
              return (
                <FormControlLabel
                  key={assetOwnership.id}
                  value={assetOwnership.id}
                  sx={{ marginTop: "16px" }}
                  control={<Radio color="secondary" />}
                  label={assetOwnership.description}
                />
              );
            })
          )}
        </RadioGroup>
      </FormGroup>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Grupo de calidad
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="qualityGroupId"
              name="qualityGroupId"
              variant="outlined"
              value={formik.values.qualityGroupId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "qualityGroupId",
                  formik
                );
              }}
              error={
                formik.touched.qualityGroupId &&
                Boolean(formik.errors.qualityGroupId)
              }
              helperText={
                formik.touched.qualityGroupId && formik.errors.qualityGroupId
              }
            >
              {isLoadingQualityGroups ? (
                <CircularProgress />
              ) : (
                qualityGroups.map((qualityGroup) => {
                  return (
                    <MenuItem key={qualityGroup.id} value={qualityGroup.id}>
                      {qualityGroup.sui_code}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Zona SUI
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="zoneSuiId"
              name="zoneSuiId"
              variant="outlined"
              value={formik.values.zoneSuiId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(e.target.value, "zoneSuiId", formik);
              }}
              error={
                formik.touched.zoneSuiId && Boolean(formik.errors.zoneSuiId)
              }
              helperText={formik.touched.zoneSuiId && formik.errors.zoneSuiId}
            >
              {isLoadingZoneSui ? (
                <CircularProgress />
              ) : (
                zonesSui.map((zoneSui) => {
                  return (
                    <MenuItem key={zoneSui.id} value={zoneSui.id}>
                      {zoneSui.description}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Condición especial
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="specialConditionId"
              name="specialConditionId"
              variant="outlined"
              value={formik.values.specialConditionId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "specialConditionId",
                  formik
                );
              }}
              error={
                formik.touched.specialConditionId &&
                Boolean(formik.errors.specialConditionId)
              }
              helperText={
                formik.touched.specialConditionId &&
                formik.errors.specialConditionId
              }
            >
              {isLoadingSpecialConditions ? (
                <CircularProgress />
              ) : (
                specialConditions.map((specialCondition) => {
                  return (
                    <MenuItem
                      key={specialCondition.id}
                      value={specialCondition.id}
                    >
                      {specialCondition.description}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Tipo de área especial
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="specialAreaTypeId"
              name="specialAreaTypeId"
              variant="outlined"
              value={formik.values.specialAreaTypeId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "specialAreaTypeId",
                  formik
                );
              }}
              error={
                formik.touched.specialAreaTypeId &&
                Boolean(formik.errors.specialAreaTypeId)
              }
              helperText={
                formik.touched.specialAreaTypeId &&
                formik.errors.specialAreaTypeId
              }
            >
              {isLoadingSpecialAreaTypes ? (
                <CircularProgress />
              ) : (
                specialAreaTypes.map((specialAreaType) => {
                  return (
                    <MenuItem
                      key={specialAreaType.id}
                      value={specialAreaType.id}
                    >
                      {specialAreaType.description}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <InputLabel shrink className="Input-label">
              Estrato SUI
            </InputLabel>
            <TextField
              fullWidth
              select
              className="TextField-without-border-radius"
              id="suiSocialStratumId"
              name="suiSocialStratumId"
              variant="outlined"
              value={formik.values.suiSocialStratumId}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "suiSocialStratumId",
                  formik
                );
              }}
              error={
                formik.touched.suiSocialStratumId &&
                Boolean(formik.errors.suiSocialStratumId)
              }
              helperText={
                formik.touched.suiSocialStratumId &&
                formik.errors.suiSocialStratumId
              }
            >
              {isLoadingSuiSocialStratums ? (
                <CircularProgress />
              ) : (
                suiSocialStratum.map((suiSocialStratum) => {
                  return (
                    <MenuItem
                      key={suiSocialStratum.id}
                      value={suiSocialStratum.id}
                    >
                      {`${suiSocialStratum.sui_code} - ${suiSocialStratum.description}`}
                    </MenuItem>
                  );
                }) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
        </Grid>
      </Grid>

      <Box>
        <FormGroup sx={{ marginTop: "26px" }}>
          <InputLabel shrink className="Input-label">
            Tipo de autogerador
          </InputLabel>
          <TextField
            fullWidth
            select
            className="TextField-without-border-radius"
            id="autogeneratorTypeId"
            name="autogeneratorTypeId"
            variant="outlined"
            disabled={
              service != null && formik.values.autogeneratorTypeId === "3"
            }
            value={formik.values.autogeneratorTypeId}
            onChange={(e) => {
              const value = e.target.value;
              e.preventDefault();
              handleTextFieldChanged(value, "autogeneratorTypeId", formik);
            }}
            error={
              formik.touched.autogeneratorTypeId &&
              Boolean(formik.errors.autogeneratorTypeId)
            }
            helperText={
              formik.touched.autogeneratorTypeId &&
              formik.errors.autogeneratorTypeId
            }
          >
            {isLoadingAutoGeneratorTypes ? (
              <CircularProgress />
            ) : (
              autogeneratorTypes.map((autogeneratorType) => {
                return (
                  <MenuItem
                    key={autogeneratorType.id}
                    value={autogeneratorType.sui_code}
                  >
                    {autogeneratorType.description}
                  </MenuItem>
                );
              }) ?? (
                <Typography variant="body1">
                  No hemos encontrado ningún resultado
                </Typography>
              )
            )}
          </TextField>
        </FormGroup>
        {shouldShowDependentAutoGeneratorTypeFields(
          autogeneratorTypes,
          formik.values.autogeneratorTypeId
        ) && (
          <>
            <FormGroup sx={{ marginTop: "26px" }}>
              <InputLabel shrink className="Input-label">
                Código de frontera ante XM
              </InputLabel>
              <TextField
                disabled={disabled}
                fullWidth
                className="TextField-without-border-radius"
                id="frontierXmAutogeneratorCode"
                name="frontierXmAutogeneratorCode"
                variant="outlined"
                value={formik.values.frontierXmAutogeneratorCode}
                helperText={
                  formik.touched.frontierXmAutogeneratorCode &&
                  formik.errors.frontierXmAutogeneratorCode
                }
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(
                    e.target.value,
                    "frontierXmAutogeneratorCode",
                    formik
                  );
                }}
                error={
                  formik.touched.frontierXmAutogeneratorCode &&
                  Boolean(formik.errors.frontierXmAutogeneratorCode)
                }
              />
            </FormGroup>
            <FormGroup sx={{ marginTop: "26px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    id="backUpAgreement"
                    name="backUpAgreement"
                    checked={formik.values.backUpAgreement}
                    onChange={(_, checked) => {
                      handleTextFieldChanged(
                        checked,
                        "backUpAgreement",
                        formik
                      );
                    }}
                    color="secondary"
                  />
                }
                label="Contrato de respaldo"
              />
            </FormGroup>
            <FormGroup sx={{ marginTop: "26px" }}>
              <InputLabel shrink className="Input-label">
                Capacidad de respaldo
              </InputLabel>
              <TextField
                disabled={disabled}
                fullWidth
                className="TextField-without-border-radius"
                id="backUpAgreementPowerCapacity"
                name="backUpAgreementPowerCapacity"
                variant="outlined"
                value={formik.values.backUpAgreementPowerCapacity}
                helperText={
                  formik.touched.backUpAgreementPowerCapacity &&
                  formik.errors.backUpAgreementPowerCapacity
                }
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(
                    e.target.value,
                    "backUpAgreementPowerCapacity",
                    formik
                  );
                }}
                error={
                  formik.touched.backUpAgreementPowerCapacity &&
                  Boolean(formik.errors.backUpAgreementPowerCapacity)
                }
              />
            </FormGroup>
            <FormGroup sx={{ marginTop: "26px" }}>
              <InputLabel id="radioExportEnergy">Energía exportada</InputLabel>
              <RadioGroup
                aria-disabled={disabled}
                aria-labelledby="radioExportEnergy"
                value={formik.values.exportEnergyId}
                name="exportEnergyId"
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(
                    e.target.value,
                    "exportEnergyId",
                    formik
                  );
                }}
              >
                {isLoadingExportEnergy ? (
                  <CircularProgress />
                ) : (
                  exportEnergy.map((exportEnergy) => {
                    return (
                      <FormControlLabel
                        key={exportEnergy.id}
                        value={exportEnergy.id}
                        sx={{ marginTop: "16px" }}
                        control={<Radio color="secondary" />}
                        label={exportEnergy.description}
                      />
                    );
                  })
                )}
              </RadioGroup>
            </FormGroup>
          </>
        )}
      </Box>
      {shouldShowDependentExportEnergyFields(exportEnergy, formik) && (
        <>
          <FormGroup sx={{ marginTop: "26px" }}>
            <InputLabel shrink className="Input-label">
              Capacidad de energía exportada
            </InputLabel>
            <TextField
              fullWidth
              className="TextField-without-border-radius"
              id="exportEnergyPowerCapacity"
              name="exportEnergyPowerCapacity"
              variant="outlined"
              value={formik.values.exportEnergyPowerCapacity}
              helperText={
                formik.touched.exportEnergyPowerCapacity &&
                formik.errors.exportEnergyPowerCapacity
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "exportEnergyPowerCapacity",
                  formik
                );
              }}
              error={
                formik.touched.exportEnergyPowerCapacity &&
                Boolean(formik.errors.exportEnergyPowerCapacity)
              }
            />
          </FormGroup>
          <FormGroup sx={{ marginTop: "26px" }}>
            <InputLabel shrink className="Input-label">
              Tipo de la generación de la energía exportada
            </InputLabel>
            <TextField
              disabled={disabled}
              fullWidth
              select
              className="TextField-without-border-radius"
              id="exportEnergyGenerationTypeId"
              name="exportEnergyGenerationTypeId"
              variant="outlined"
              value={formik.values.exportEnergyGenerationTypeId}
              helperText={
                formik.touched.exportEnergyGenerationTypeId &&
                formik.errors.exportEnergyGenerationTypeId
              }
              onBlur={formik.handleBlur}
              onChange={(e) => {
                e.preventDefault();
                handleTextFieldChanged(
                  e.target.value,
                  "exportEnergyGenerationTypeId",
                  formik
                );
              }}
              error={
                formik.touched.exportEnergyGenerationTypeId &&
                Boolean(formik.errors.exportEnergyGenerationTypeId)
              }
            >
              {isLoadingExportEnergyGenerationTypes ? (
                <CircularProgress />
              ) : (
                exportEnergyGenerationTypes.map(
                  (exportEnergyGenerationType) => {
                    return (
                      <MenuItem
                        key={exportEnergyGenerationType.id}
                        value={exportEnergyGenerationType.id}
                      >
                        {exportEnergyGenerationType.description}
                      </MenuItem>
                    );
                  }
                ) ?? (
                  <Typography variant="body1">
                    No hemos encontrado ningún resultado
                  </Typography>
                )
              )}
            </TextField>
          </FormGroup>
          <FormGroup sx={{ marginTop: "26px" }}>
            <InputLabel shrink className="Input-label">
              Fecha de inicio de la energía exportada
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disabled={disabled}
                onChange={(value: moment.Moment | null) => {
                  if (value) {
                    handleTextFieldChanged(
                      value.startOf("day"),
                      "exportEnergyStartDatetime",
                      formik
                    );
                  }
                }}
                value={formik.values.exportEnergyStartDatetime}
                slotProps={{
                  textField: (params: any) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "De",
                        format: "yyyy-MM-dd",
                      }}
                      sx={{ width: "100%" }}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </FormGroup>
        </>
      )}
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel id="radioPortafolioRiskUserType">
          Tipo de usuario riesgo cartera
        </InputLabel>
        <RadioGroup
          aria-labelledby="radioPortafolioRiskUserType"
          value={formik.values.portafolioRiskUserTypeId}
          name="portafolioRiskUserTypeId"
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(
              e.target.value,
              "portafolioRiskUserTypeId",
              formik
            );
          }}
        >
          {isLoadingPortafolioRiskUserType ? (
            <CircularProgress />
          ) : (
            portafolioRiskUserTypes.map((portafolioRiskUserType) => {
              return (
                <FormControlLabel
                  key={portafolioRiskUserType.id}
                  value={portafolioRiskUserType.id}
                  sx={{ marginTop: "16px" }}
                  control={<Radio color="secondary" />}
                  label={portafolioRiskUserType.description}
                />
              );
            })
          )}
        </RadioGroup>
      </FormGroup>
      <FormGroup sx={{ marginTop: "36px" }}>
        <InputLabel id="radioButtonsWalletRisk">
          Consumo de subsistencia
        </InputLabel>
        <RadioGroup
          aria-labelledby="radioSubsistenceConsumption"
          value={formik.values.subsistenceConsumptionId}
          name="subsistenceConsumptionId"
          onChange={(e) => {
            e.preventDefault();
            handleTextFieldChanged(
              e.target.value,
              "subsistenceConsumptionId",
              formik
            );
          }}
        >
          {isLoadingSubsistenceConsumption ? (
            <CircularProgress />
          ) : (
            subsistenceConsumption.map((subsistenceConsumption) => {
              return (
                <FormControlLabel
                  key={subsistenceConsumption.id}
                  value={subsistenceConsumption.id}
                  sx={{ marginTop: "16px" }}
                  control={<Radio color="secondary" />}
                  label={subsistenceConsumption.description}
                />
              );
            })
          )}
        </RadioGroup>
      </FormGroup>
    </>
  );
};

export default RegulatoryInformationForm;
